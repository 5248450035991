
import { DiagramTool } from '@icepanel/app-canvas'
import { diagramObjectTypes, ModelObjectType } from '@icepanel/platform-api-client'
import Fuse from 'fuse.js'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import { iconUrlForTheme } from '@/helpers/theme'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { objectDescriptions, objectIcons } from '@/modules/model/helpers/objects'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { ModelModule } from '../../store'

interface IModelObjectType {
  type: ModelObjectType
  text: string
  icon: string
  tooltip: string
}

@Component({
  components: {
    Menu
  },
  name: 'ModelObjectAssignMenu'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly menuRef!: Menu

  @Prop({ default: false }) readonly disabled?: boolean
  @Prop() readonly position!: { x: number, y: number }
  @Prop() readonly originModelId!: string | null

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramContent () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramContentModelObjectIds () {
    return Object.values(this.currentDiagramContent?.objects || {}).map(o => o.modelId)
  }

  get toolbarSelection () {
    return this.editorModule.toolbarSelection
  }

  get modelObjectItemsHeight () {
    return Math.min(5 * 32, this.modelObjectItemsFuzzy.length * 32)
  }

  get modelObjectTypes (): IModelObjectType[] {
    if (this.toolbarSelection === 'add') {
      const items: IModelObjectType[] = [
        {
          icon: objectIcons.system,
          text: 'System',
          tooltip: objectDescriptions.system,
          type: 'system'
        },
        {
          icon: objectIcons.actor,
          text: 'Actor',
          tooltip: objectDescriptions.actor,
          type: 'actor'
        },
        {
          icon: objectIcons.group,
          text: 'Group',
          tooltip: objectDescriptions.group,
          type: 'group'
        }
      ]
      if (this.currentDiagram?.type === 'component-diagram' || this.currentDiagram?.type === 'app-diagram') {
        items.push(
          {
            icon: objectIcons.app,
            text: 'App',
            tooltip: objectDescriptions.app,
            type: 'app'
          },
          {
            icon: objectIcons.store,
            text: 'Store',
            tooltip: objectDescriptions.store,
            type: 'store'
          }
        )
      }
      if (this.currentDiagram?.type === 'component-diagram') {
        items.push(
          {
            icon: objectIcons.component,
            text: 'Component',
            tooltip: objectDescriptions.component,
            type: 'component'
          }
        )
      }
      return items
    } else if (this.toolbarSelection === 'actor') {
      return [
        {
          icon: objectIcons.actor,
          text: 'Actor',
          tooltip: objectDescriptions.actor,
          type: 'actor'
        }
      ]
    } else if (this.toolbarSelection === 'app') {
      return [
        {
          icon: objectIcons.app,
          text: 'App',
          tooltip: objectDescriptions.app,
          type: 'app'
        }
      ]
    } else if (this.toolbarSelection === 'store') {
      return [
        {
          icon: objectIcons.store,
          text: 'Store',
          tooltip: objectDescriptions.store,
          type: 'store'
        }
      ]
    } else if (this.toolbarSelection === 'system') {
      return [
        {
          icon: objectIcons.system,
          text: 'System',
          tooltip: objectDescriptions.system,
          type: 'system'
        }
      ]
    } else if (this.toolbarSelection === 'component') {
      return [
        {
          icon: objectIcons.component,
          text: 'Component',
          tooltip: objectDescriptions.component,
          type: 'component'
        }
      ]
    } else if (this.toolbarSelection === 'group') {
      return [
        {
          icon: objectIcons.group,
          text: 'Group',
          tooltip: objectDescriptions.group,
          type: 'group'
        }
      ]
    } else {
      return []
    }
  }

  get modelObjectItems () {
    return Object
      .values(this.modelModule.objects)
      .filter(o =>
        o.type !== 'root' &&
        diagramObjectTypes[this.currentDiagram?.type || 'context-diagram'].includes(o.type) &&
        !this.currentDiagramContentModelObjectIds.includes(o.id) &&
        (this.toolbarSelection === 'add' || this.toolbarSelection === o.type)
      )
      .map(o => ({
        caption: `${o.type.slice(0, 1).toUpperCase()}${o.type.slice(1)}`,
        diagramCount: Object.keys(o.diagrams).length,
        icon: o.icon ? iconUrlForTheme(o.icon) : undefined,
        iconName: o.icon?.name,
        id: o.id,
        parentId: o.parentId,
        text: o.name || `${o.type.slice(0, 1).toUpperCase()}${o.type.slice(1)}`,
        type: o.type
      }))
  }

  get modelObjectItemsSorted () {
    if (this.originModelId) {
      const connectionsToOrigin: Record<string, number> = {}
      Object.values(this.modelModule.connections).forEach(o => {
        if (o.originId === this.originModelId) {
          connectionsToOrigin[o.targetId] = connectionsToOrigin[o.targetId] || 0
          connectionsToOrigin[o.targetId]++
        } else if (o.targetId === this.originModelId) {
          connectionsToOrigin[o.originId] = connectionsToOrigin[o.originId] || 0
          connectionsToOrigin[o.originId]++
        }
      })
      return this.modelObjectItems.sort((a, b) => {
        const aConnections = connectionsToOrigin[a.id] || 0
        const bConnections = connectionsToOrigin[b.id] || 0
        return bConnections - aConnections
      })
    } else {
      return this.modelObjectItems.sort((a, b) => {
        if (this.currentDiagram && a.parentId === this.currentDiagram.modelId && b.parentId === this.currentDiagram.modelId && a.diagramCount === b.diagramCount) {
          return a.text.localeCompare(b.text)
        } else if (this.currentDiagram && a.parentId === this.currentDiagram.modelId && b.parentId === this.currentDiagram.modelId) {
          return b.diagramCount - a.diagramCount
        } else if (this.currentDiagram && a.parentId === this.currentDiagram.modelId && b.parentId !== this.currentDiagram.modelId) {
          return -1
        } else if (this.currentDiagram && a.parentId !== this.currentDiagram.modelId && b.parentId === this.currentDiagram.modelId) {
          return 1
        } else {
          return 0
        }
      })
    }
  }

  get modelObjectItemsFuzzy () {
    if (this.editorModule.objectCreateFilterName) {
      const search = new Fuse(this.modelObjectItemsSorted, {
        keys: [
          'text',
          'caption',
          'iconName'
        ],
        threshold: 0.3
      })
      return search.search(this.editorModule.objectCreateFilterName).map(o => o.item)
    } else {
      return this.modelObjectItemsSorted
    }
  }

  mounted () {
    this.menuRef.open()
  }

  updateDimensions () {
    this.menuRef.updateDimensions()
  }

  setToolbarSelection (tool: DiagramTool) {
    this.editorModule.setToolbarSelection(tool)
  }
}
