
import { Context } from '@icepanel/app-canvas'
import { layoutAreaObjects, objectConnectionPoints } from '@icepanel/app-graphics'
import { DiagramContentPartial, DiagramObject, ModelObject, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

@Component({
  name: 'ModelObjectExpand'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly object!: DiagramObject
  @Prop() readonly modelObject!: ModelObject

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)!
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get expandable () {
    if (this.$routeName === 'editor-diagram' && 'type' in this.object) {
      const modelObject = this.modelModule.objects[this.object.modelId]
      const modelObjectFamilyIds = [modelObject.id, ...modelObject.parentIds]
        .map(o => this.modelModule.objects[o])
        .filter(o => o.type !== 'root')
        .map(o => o.id)
      return (
        (this.currentDiagram.type === 'app-diagram' && modelObject.type === this.object.type && modelObject.type === 'system' && !modelObjectFamilyIds.includes(this.currentDiagram.modelId)) ||
        (this.currentDiagram.type === 'component-diagram' && modelObject.type === this.object.type && (modelObject.type === 'system' || modelObject.type === 'app' || modelObject.type === 'store') && !modelObjectFamilyIds.includes(this.currentDiagram.modelId))
      )
    } else {
      return false
    }
  }

  get collapsable () {
    if (this.$routeName === 'editor-diagram' && 'type' in this.object && this.object.shape === 'area' && this.object.type !== 'group') {
      const modelObject = this.modelModule.objects[this.object.modelId]
      const modelObjectFamilyIds = [this.currentDiagram.modelId, ...this.modelModule.objects[this.currentDiagram.modelId]?.parentIds || []]
        .map(o => this.modelModule.objects[o])
        .filter(o => o.type !== 'root')
        .map(o => o.id)
      return !modelObjectFamilyIds.includes(modelObject.id)
    } else {
      return false
    }
  }

  get expandTooltips (): Partial<Record<ModelObject['type'], string>> {
    return {
      app: 'Expand app to allow placing of existing components inside',
      store: 'Expand store to allow placing of existing components inside',
      system: 'Expand system to allow placing of existing apps inside'
    }
  }

  get collapseTooltips (): Partial<Record<ModelObject['type'], string>> {
    return {
      app: 'Collapse app and remove components from this diagram',
      store: 'Collapse store and remove components from this diagram',
      system: 'Collapse system and remove apps from this diagram'
    }
  }

  async expandObject () {
    if ('type' in this.object) {
      const objects = { ...this.currentDiagram.objects }
      const connections = { ...this.currentDiagram.connections }

      const diagramProps: Omit<DiagramContentPartial, 'commit'> = {}
      const revertDiagramProps: Omit<DiagramContentPartial, 'commit'> = {}

      revertDiagramProps.objects = {
        ...revertDiagramProps.objects,
        $update: {
          ...revertDiagramProps.objects?.$update,
          [this.object.id]: objects[this.object.id]
        }
      }
      objects[this.object.id] = {
        ...this.object,
        ...Context.Diagram.Obj.getDefaultSize('group'),
        shape: 'area'
      }
      diagramProps.objects = {
        ...diagramProps.objects,
        $update: {
          ...diagramProps.objects?.$update,
          [this.object.id]: objects[this.object.id]
        }
      }

      Object
        .entries(layoutAreaObjects(objects, this.modelModule.objects))
        .forEach(([id, rect]) => {
          revertDiagramProps.objects = {
            ...revertDiagramProps.objects,
            $update: {
              ...revertDiagramProps.objects?.$update,
              [id]: {
                ...revertDiagramProps.objects?.$update?.[id],
                height: objects[id].height,
                width: objects[id].width,
                x: objects[id].x,
                y: objects[id].y
              }
            }
          }
          objects[id] = {
            ...objects[id],
            ...rect
          }
          diagramProps.objects = {
            ...diagramProps.objects,
            $update: {
              ...diagramProps.objects?.$update,
              [id]: {
                ...diagramProps.objects?.$update?.[id],
                ...rect
              }
            }
          }
        })
      Object.entries(objectConnectionPoints(Object.values(objects), Object.values(connections))).forEach(([id, points]) => {
        revertDiagramProps.connections = {
          ...revertDiagramProps.connections,
          $update: {
            ...revertDiagramProps.connections?.$update,
            [id]: {
              ...revertDiagramProps.connections?.$update?.[id],
              points: connections[id].points
            }
          }
        }
        connections[id] = {
          ...connections[id],
          points
        }
        diagramProps.connections = {
          ...diagramProps.connections,
          $update: {
            ...diagramProps.connections?.$update,
            [id]: {
              ...diagramProps.connections?.$update?.[id],
              points
            }
          }
        }
      })

      const tasks: Task[] = []
      const revertTasks: Task[] = [{
        id: this.currentDiagram.id,
        props: revertDiagramProps,
        type: 'diagram-content-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]

      const { diagramContent, diagramContentUpdate, modelConnectionDiagramAdd, modelConnectionDiagramRemove } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, diagramProps)
      this.diagramModule.setDiagramContentVersion(diagramContent)
      this.modelModule.setConnectionDiagrams({ modelConnectionDiagramAdd, modelConnectionDiagramRemove })
      this.editorModule.addToTaskQueue({
        func: () => this.diagramModule.diagramContentUpdate({
          diagramId: this.currentDiagram.id,
          landscapeId: this.currentLandscape.id,
          props: diagramContentUpdate,
          versionId: this.currentVersion.id
        })
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks: [
          ...tasks,
          {
            id: diagramContent.id,
            props: diagramContentUpdate,
            type: 'diagram-content-update'
          },
          {
            route: this.$route,
            type: 'navigation'
          }
        ]
      })
    }
  }

  async collapseObject () {
    if ('type' in this.modelObject && this.modelObject.type !== 'root' && 'type' in this.object) {
      const objects = { ...this.currentDiagram.objects }
      const connections = { ...this.currentDiagram.connections }

      const diagramProps: Omit<DiagramContentPartial, 'commit'> = {}
      const revertDiagramProps: Omit<DiagramContentPartial, 'commit'> = {}

      revertDiagramProps.objects = {
        ...revertDiagramProps.objects,
        $update: {
          ...revertDiagramProps.objects?.$update,
          [this.object.id]: objects[this.object.id]
        }
      }
      objects[this.object.id] = {
        ...this.object,
        ...Context.Diagram.Obj.getDefaultSize(this.modelObject.type),
        shape: 'box'
      }
      diagramProps.objects = {
        ...diagramProps.objects,
        $update: {
          ...diagramProps.objects?.$update,
          [this.object.id]: objects[this.object.id]
        }
      }

      const childObjectModelIds = Object.values(this.modelModule.objects).filter(o => o.parentIds.includes(this.object.modelId)).map(o => o.id)
      const childObjectIds = Object
        .values(objects)
        .filter(o => childObjectModelIds.includes(o.modelId))
        .map(o => {
          revertDiagramProps.objects = {
            ...revertDiagramProps.objects,
            $add: {
              ...revertDiagramProps.objects?.$add,
              [o.id]: objects[o.id]
            }
          }
          delete objects[o.id]
          diagramProps.objects = {
            ...diagramProps.objects,
            $remove: [
              ...diagramProps.objects?.$remove || [],
              o.id
            ]
          }
          return o.id
        })
      Object
        .values(connections)
        .filter(o => o.originId && childObjectIds.includes(o.originId))
        .forEach(o => {
          revertDiagramProps.connections = {
            ...revertDiagramProps.connections,
            $update: {
              ...revertDiagramProps.connections?.$update,
              [o.id]: {
                ...revertDiagramProps.connections?.$update?.[o.id],
                originId: connections[o.id].originId
              }
            }
          }
          connections[o.id] = {
            ...connections[o.id],
            originId: this.object.id
          }
          diagramProps.connections = {
            ...diagramProps.connections,
            $update: {
              ...diagramProps.connections?.$update,
              [o.id]: {
                ...diagramProps.connections?.$update?.[o.id],
                originId: this.object.id
              }
            }
          }
        })
      Object
        .values(connections)
        .filter(o => o.targetId && childObjectIds.includes(o.targetId))
        .forEach(o => {
          revertDiagramProps.connections = {
            ...revertDiagramProps.connections,
            $update: {
              ...revertDiagramProps.connections?.$update,
              [o.id]: {
                ...revertDiagramProps.connections?.$update?.[o.id],
                targetId: connections[o.id].targetId
              }
            }
          }
          connections[o.id] = {
            ...connections[o.id],
            targetId: this.object.id
          }
          diagramProps.connections = {
            ...diagramProps.connections,
            $update: {
              ...diagramProps.connections?.$update,
              [o.id]: {
                ...diagramProps.connections?.$update?.[o.id],
                targetId: this.object.id
              }
            }
          }
        })

      Object.entries(layoutAreaObjects(objects, this.modelModule.objects)).forEach(([id, rect]) => {
        revertDiagramProps.objects = {
          ...revertDiagramProps.objects,
          $update: {
            ...revertDiagramProps.objects?.$update,
            [id]: {
              ...revertDiagramProps.objects?.$update?.[id],
              height: objects[id].height,
              width: objects[id].width,
              x: objects[id].x,
              y: objects[id].y
            }
          }
        }
        objects[id] = {
          ...objects[id],
          ...rect
        }
        diagramProps.objects = {
          ...diagramProps.objects,
          $update: {
            ...diagramProps.objects?.$update,
            [id]: {
              ...diagramProps.objects?.$update?.[id],
              ...rect
            }
          }
        }
      })
      Object.entries(objectConnectionPoints(Object.values(objects), Object.values(connections))).forEach(([id, points]) => {
        revertDiagramProps.connections = {
          ...revertDiagramProps.connections,
          $update: {
            ...revertDiagramProps.connections?.$update,
            [id]: {
              ...revertDiagramProps.connections?.$update?.[id],
              points: connections[id].points
            }
          }
        }
        connections[id] = {
          ...connections[id],
          points
        }
        diagramProps.connections = {
          ...diagramProps.connections,
          $update: {
            ...diagramProps.connections?.$update,
            [id]: {
              ...diagramProps.connections?.$update?.[id],
              points
            }
          }
        }
      })

      const tasks: Task[] = []
      const revertTasks: Task[] = [{
        id: this.currentDiagram.id,
        props: revertDiagramProps,
        type: 'diagram-content-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]

      const { diagramContent, diagramContentUpdate, modelConnectionDiagramAdd, modelConnectionDiagramRemove } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, diagramProps)
      this.diagramModule.setDiagramContentVersion(diagramContent)
      this.modelModule.setConnectionDiagrams({ modelConnectionDiagramAdd, modelConnectionDiagramRemove })
      this.editorModule.addToTaskQueue({
        func: () => this.diagramModule.diagramContentUpdate({
          diagramId: this.currentDiagram.id,
          landscapeId: this.currentLandscape.id,
          props: diagramContentUpdate,
          versionId: this.currentVersion.id
        })
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks: [
          ...tasks,
          {
            id: diagramContent.id,
            props: diagramContentUpdate,
            type: 'diagram-content-update'
          },
          {
            route: this.$route,
            type: 'navigation'
          }
        ]
      })
    }
  }
}
